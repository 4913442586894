@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


@font-face {
  font-family: "GangOfThree";
  src: url("../public/fonts/go3v2.ttf");
}

@font-face {
  font-family: "PressStart2P";
  src: url("../public/fonts/PressStart2P-Regular.ttf");
}
@font-face {
  font-family: "Sakurata";
  src: url("../public/fonts/SAKURATA.ttf");
}
@font-face {
  font-family: "GangOfThree";
  src: url("../public/fonts/DePixelSchmal.ttf");
}
@font-face {
  font-family: "MouseMemoirs";
  src: url("../public/fonts/MouseMemoirs-Regular.ttf");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.game-bg {
  background-image: url(../public/imgs/game-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.main-page-bg {
  background-image: url(../public/bg-main-page.png);
}
.roman {
  border: 1rem solid;
  border-image-source: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" fill="rgb(40 3 61)"><path d="M3 0h1a.5.5 0 0 1 0 1c0 1.1.9 2 2 2a.5.5 0 0 1 1 0v1a.5.5 0 0 1-1 0 2 2 0 0 0-2 2 .5.5 0 0 1 0 1H3a.5.5 0 0 1 0-1 2 2 0 0 0-2-2 .5.5 0 0 1-1 0V3a.5.5 0 0 1 1 0 2 2 0 0 0 2-2 .5.5 0 0 1 0-1Z" /></svg>');
  border-image-slice: 3 fill;
}

::-webkit-scrollbar {
  width: 16px;
  height: 90%;
  scroll-behavior: smooth;
  scroll-snap-align: end;
}

::-webkit-scrollbar-track {
  background: #131112;
}

::-webkit-scrollbar-thumb {
  background-color: #247115;
  border-radius: 20px;
  border: 1px solid #247115;
}
